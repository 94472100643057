import React, { Component , createRef} from 'react'

import HeaderComponent from '../header/HeaderComponent'
import KnowLoanComponent from '../knowloan/KnowloanComponent'
import FindLoanComponent from '../findloan/FindloanComponent'
import CarouselComponent from '../carousel/CarouselComponent'
import LenderComponent from '../lender/LenderComponent'
import FooterComponent from '../footer/FooterComponent'
import TestimonialsComponent from '../testimonials/TestimonialsComponent'
//import { LoansHelp } from '../educationPopup/LoansHelp'

import {getGlidEnd} from '../../utils';
const glidEnd = getGlidEnd();

export class MainPage extends Component {

  constructor(props) {
    super(props);
    this.lendersRef = createRef();
    this.updateNewFormState = this.updateNewFormState.bind(this);
  }

  // scrollToLenders = () => {
  //   if (this.lendersRef.current) {
  //     this.lendersRef.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };

  updateNewFormState(newValue) {
    const {updateNewFormStateRouter} = this.props;
    updateNewFormStateRouter(true)
  }

  render() {
    return (
      <>
      <div style={{backgroundColor:'#F8F9FA'}}>
      <HeaderComponent/>
      <CarouselComponent/>
      <LenderComponent ref={this.lendersRef} newForm={this.props.newForm} updateNewFormState={this.updateNewFormState}/>
      <KnowLoanComponent/>
      <TestimonialsComponent/>
      <FindLoanComponent/>
      <FooterComponent/>
      {/* {(glidEnd == '0' || glidEnd == '1' || glidEnd == '2' || glidEnd == '3' || glidEnd == '4') && <LoansHelp scrollTo = {this.scrollToLenders} />} */}
      </div>
      </>
    )
  }
}

export default MainPage;
