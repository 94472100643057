import React, { useEffect, useRef, useState, createContext, useContext } from 'react'
import axios from "axios";
import FormComponent from '../newHomeComponents/FormComponent'
import BenefitsComponent from '../newHomeComponents/BenefitsComponent'
import HeaderComponent from '../header/HeaderComponent'
import PartnerComponent from '../crm/PartnerComponent'
import FooterComponent from '../footer/FooterComponent'
import { getGlid } from '../../utils'

import { gatrack } from '../../utils';

const NewHomeContext = createContext();
export const useNewHomeContext = () => useContext(NewHomeContext);

function NewHomePage() {
  const targetDivRef = useRef(null);


  const scrollToTarget = () => {
    targetDivRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    gatrack("Intent Capture","Landing","Landed")
    console.log("in use Effect");
  }, [])
  return (
    <>
      <NewHomeContext.Provider>
        <HeaderComponent />
        <FormComponent ref={targetDivRef} />
        <BenefitsComponent onClick={scrollToTarget} />
        <FooterComponent />
      </NewHomeContext.Provider>
    </>
  )
}

export default NewHomePage