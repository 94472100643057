import React from 'react'
import "../../CssFiles/newHomePage.css"
import { useRef } from "react"
 

const guideIcon = 'https://loans.imimg.com/images/loans/newHomeImages/guideIcon.png'
const trustedIcon = 'https://loans.imimg.com/images/loans/newHomeImages/trustedIcon.png'
const coinsIcon = 'https://loans.imimg.com/images/loans/newHomeImages/coinsIcon.png'
const paperlesIcon ='https://loans.imimg.com/images/loans/newHomeImages/paperlessIcon.png'

export default function BenefitsComponent({ onClick }) {

    return (
        <>
            <div id='feature' className='feature'>
            <div id='rowHelloTrade' className='row' style={{width:'100%'}}>
                <div id='fourBenefit' className='col-md-6 col-12'>
                <div className='featureBoxes'>
                <div className = 'row'>
                        <div className='col-6'>
                            <div className='featureBox'>
                                <img src={paperlesIcon} alt='paperless' className = 'featureBoxImage'/>
                                <div className='featureBoxText'>100% paperless process</div>
                            </div>
                        </div>
                        <div className='col-6'>
                        <div className='featureBox'>
                        <img src={trustedIcon} alt='trusted' className = 'featureBoxImage'/>
                        <div className='featureBoxText'>Trusted by MSMEs</div>
                        </div>
                        </div>
                    </div>
                    <div className = 'row' style={{marginTop:"30px"}}>
                        <div className='col-6'>
                        <div className='featureBox'>
                        <img src={guideIcon} alt='guide' className = 'featureBoxImage'/>
                        <div className='featureBoxText'>Always there to guide you</div>
                        </div>
                        </div>
                        <div className='col-6'>
                        <div className='featureBox'>
                        <img src={trustedIcon} alt='trusted' className = 'featureBoxImage'/>
                        <div className='featureBoxText'>Service across
                        country</div>
                        </div>
                        </div>
                    </div>
                </div>
                </div>
                <div id='betterWay' className='col-md-6 col-12'>
                    <div className='betterBox'>
                        <div id='featureH1'>Why we are better in every way?</div>
                        <div id='featurePara'>We offer a seamless digital experience and stand by you through every step of your loan journey and beyond. Trusted nationwide, we bring tailored solutions to meet your financial needs across India.</div>
                        <div className='get-btn' onClick={onClick}>Get Started</div>
                        <img src={coinsIcon} alt='coins' id='coinsIcon'/>
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}
