import Cookies from 'universal-cookie';
import axios from 'axios';
import { format } from 'date-fns';

export function getGlid(){
    const cookie = new Cookies();

    var glid = '';

    let visitorData = cookie.get('ImeshVisitor');
    if (visitorData) {
        visitorData = visitorData.split("|");

        let sd = [];
        visitorData.map((data) => {
            sd = data.split("=")
            if (sd[0] == "glid") {
                glid = sd[1]
            }
        })
    }
    return glid;
}

export function clicktracking(vendor_name) {

    var glid = getGlid();
    var host = window.location.hostname;

    if(host == 'loans.indiamart.com'){
        var getlenderorderURL = "https://loans.indiamart.com/functions.php";
    }
    else{
        var getlenderorderURL = "https://dev-loans.indiamart.com/functions.php";
    }

    var getlenderorderData = {
        "glid" : glid,
        "functionName" : "StoreLenderOrderData",
        "lenderorder" : 'NA',
        "action" : "Apply Now Click-SubPages",
        "ApplyNow" : vendor_name,
        "device" : "Desktop" 
    };

    var formData = new FormData();
    for (var key in getlenderorderData) {
        formData.append(key, getlenderorderData[key]);
    }

    if(glid)
    {
        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: getlenderorderURL,
            data: formData,
            timeout:10000
            }).then(function (response) {
            })
    }

}

export function gatrack(category, action, label, value = null) {
    
    var glid = getGlid();
    if (value) {
        window.imgtm.push({ 'event': 'IMEvent', 'eventCategory': category, 'eventAction': action, 'eventLabel': label, 'eventValue': value });
    }
    else {
        window.imgtm.push({ 'event': 'IMEvent', 'eventCategory': category, 'eventAction': action, 'eventLabel': label, 'eventValue': glid });
    }
}

export function glidtracker(lender_name, url_selector) {

    var glid = getGlid();

    if(lender_name == 'ICICI Business Loan')
    {
        var url = "https://clos.icicibank.com/CAOOD/Apply.jsp?channelId=IndiaMART&utm_source=IndiaMART&utm_medium=BankStatement&utm_campaign=Overdraft&utm_id=InstaOD+Plus";
    }
    else if(lender_name == 'Poonawalla Business Loan')
    {
        var url = "https://poonawallafincorp.com/poonawalla-indiamart-business-loan.php?utm_source=IndiaMart_BLSF&utm_medium=website&utm_campaign=IndiaMart_BL &utm_term=business+loan&utm_content=bl&gclid=";
    }
    else if(lender_name == 'Tata Capital Business Loan'){
        if (url_selector == "Apply Now") {
            var url = "https://www.tatacapital.com/online/loans/business-loans/home?sourceName=INDIAMART"
        }
        else {
            var url = "https://businessloan.tatacapital.com/online-tma/loans/tma/track-my-application#!"
        }
    }
    else if(lender_name == 'Indifi Business Loan'){
        var url = "https://www.indifi.com/associate/indiamart?utm_source=GLID&utm_campaign="
    }
    else if(lender_name == 'IIFL Business Loan'){
        var url = " https://iifl-loans.finbox.in/?partnerCode=II_YATOYJ&customerID="
    }

    

    if (glid) {
        if(lender_name == 'ICICI Business Loan')
        {
            url += ("&glid=" + glid);
        }
        else if(lender_name == 'Poonawalla Business Loan' || lender_name == 'Indifi Business Loan' || lender_name == 'IIFL Business Loan')
        {
            url += glid;
        }
        else if(lender_name == 'Tata Capital Business Loan')
        {
            url += ("&PartnerID=" + glid);
        }
        window.open(url, '_blank');
    }
    else {
        window.open(url, '_blank');
    }
}

export async function geturldata(vendor_name, url_selector) {
    var glid = getGlid();

    if(glid && glid != ''){
    var host = window.location.hostname;
    var gstData = await getGstData(glid);
    var encodedGstData = btoa(JSON.stringify(gstData));


    if (host == 'loans.indiamart.com') {
        var geturldataURL = "https://loans.indiamart.com/functions.php";
    }
    else {
        var geturldataURL = "https://dev-loans.indiamart.com/functions.php";
    }
    
    var geturldataPostData = {
        "functionName" : "geturldata",
        "glid": glid,
        "vendor_name": vendor_name,
        "url_selector": url_selector,
        "gstData": encodedGstData,
    };

    var formData = new FormData();
    for (var key in geturldataPostData) {
        formData.append(key, geturldataPostData[key]);
    }

        var response = await axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: geturldataURL,
            data: formData,
            timeout: 10000
        })

            var data_obj = response['data'];
            window.open(data_obj.red_url, '_blank');

    }
    else {
        if (vendor_name === "CreditEnable Unsecured") {
            window.open('https://www.creditenable.com/in/sme-business-loan/unsecured-business-loans/indiamart-sme-business-loans-v1?', '_blank');
        }
        else if (vendor_name === "CreditEnable Secured") {
            window.open('https://www.creditenable.com/in/sme-business-loan/unsecured-business-loans/indiamart-sme-business-loans-v1?', '_blank');
        }
        else if (vendor_name === "Pirimid") {
            window.open('https://indiamart.ignosis.ai/lsp/ui/api/indiamart/tenant/loan/init', '_blank');
        }
        else if (vendor_name === "CreditMantri") {
            window.open('https://www.creditmantri.com/Indiamart/?utm_content=alliance-lp&alliance_lender=Indiamart&utm_campaign=alliances&utm_source=Indiamart_CoBrandedPage&utm_term=alliance_indiamart', '_blank');
        }
        else if (vendor_name === "LoanTap") {
            window.open('https://loantap.in/journey/start/bl-im?', '_blank');
        }
        else if (vendor_name === "OneInfinity") {
            window.open('https://complementor.oneflo.in/complementor-widget/bil/CONN4128778344?', '_blank');
        }
        else if (vendor_name === "IndiaLends") {
            window.open('https://indiamart.indialends.com/?', '_blank');
        }
        else if (vendor_name === "Velocity") {
            window.open('https://indiamart.velocity.in/marketplace/indiamart?/', '_blank');
        }
        else if (vendor_name === "FinAgg") {
            window.open('https://portal.finagg.in/fame/indiamart/#/dealerassessment/login', '_blank');
        }

    }

}

export function getGlidEnd() {
    const glid = getGlid();
  
    var glidEnd = '';
    if (glid) {
      glidEnd = glid.charAt(glid.length - 1);
    }
  
    return glidEnd;
  }

  function storeGst(gstData, glid){

    const currentTime = new Date().getTime();
  
    const newData = {
      "gstdata":gstData,
      "timestamp": currentTime,
      "identifier":btoa(glid+'|'+'9846300')
    };
  
    localStorage.setItem('gstDetails', JSON.stringify(newData));
    //console.log("Data has been set or updated.");
  
  };
  
  
  async function gstapi(glid=''){
  
    var host = window.location.hostname;
    if (host == 'loans.indiamart.com') {
        var getgstDataURL = "https://loans.indiamart.com/api/v1/getGSTData";
    }
    else {
        var getgstDataURL = "https://dev-loans.indiamart.com/api/v1/getGSTData";
    }
  
    var getgstData = {
        "glid": glid,
        "src":"appNowClick",
    };
  
    var formData = new FormData();
    for (var key in getgstData) {
        formData.append(key, getgstData[key]);
    }
  
    try{
        let response = await axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: getgstDataURL,
            data: JSON.stringify(getgstData),
            timeout: 10000
        });
  
        var data_obj = response['data'];
        //console.log("gst data_obj =>", data_obj);
        if(data_obj && data_obj != "" && data_obj != null){
          storeGst(data_obj, glid);
        }
        return data_obj;
    }
    catch (error) {
        console.error("An error occurred in utils gstapi call ", error);
        return ""
    }
       
  } 
  
  export async function getGstData(glid) {
  
    const maxAge = 1 * 24 * 60 * 60 * 1000;
    const currentTime = new Date().getTime();
    const storedData = localStorage.getItem('gstDetails');
  
    if (storedData) {
    const { timestamp , identifier} = JSON.parse(storedData);
    const dec = atob(identifier);
    var storedId = dec.split("|")[0];
      if ((currentTime - timestamp < maxAge) && glid == storedId) {
       //console.log("Data is still valid.");
        var data = JSON.parse(storedData);
        if(data && data.gstdata){
          return data.gstdata;
        }
        return "";
      }
      else {
        try {
          var gstOutput = await gstapi(glid);
          //console.log("gst output =>", gstOutput);
          return gstOutput;
        } catch (error) {
          console.error('Error fetching GST data in getGstData utils:', error);
          return "";
        }
      }
    }
    else {
      try {
        var gstOutput = await gstapi(glid);
        //console.log("gst output =>", gstOutput);
        return gstOutput;
      } catch (error) {
        console.error('Error fetching GST data in getGstData utils:', error);
        return "";
      }
    }
  }

  export const lostReasonMap = {
    'aaa': 'Not minimum business vintage',
    'aab': 'Duplicate lead',
    'aac': 'Non-serviceable negative industry',
    'aad': 'Non-serviceable pincode tier 4 location',
    'aae': 'Same pan mobile disqualified or rejected for same lender in last 60 days',
    'aaf': 'Constitution is not proprietorship',
    'aag': 'GST disqualified',
    'aah': 'Business not registered',
    'aai': 'Low cibil score overdue in loan DPDS',
    'aaj': 'Delinquencies plus high leverage',
    'aak': 'Loan written off',
    'aal': 'High number of enquiries',
    'aam': 'Duplicate PAN',
    'aan': 'Low banking',
    'aao': 'Rejected by lender',
    'aap': 'Lack of financial discipline inconsistent revenue in recent past last 3 months turnover growth not sufficient',
    'aaq': 'Credit reject scorecard no hit banking not available',
    'aar': 'Low business turnover',
    'aas': 'Insufficient calculated income rule failed adjusted ABB should be >= 8000',
    'aat': 'Insufficient loan amount calculated ABB reason rule failed MCA >= 1L for Nexarc or MCA >= 10000',
    'aau': 'Insufficient transaction in account',
    'aav': 'Lack of financial discipline minimum average balance low negative',
    'aaw': 'Not eligible',
    'aax': 'Rejected by customer due not interested or requirement fulfilled',
    'aay': 'Journey expired',
    'aaz': 'Lead purged',
    'aba': 'Age is less'
  };

  export const loanTypeMap = {
    '0': 'Term loan',
    '1': 'Short term business loan',
    '2': 'Loan against property',
    '3': 'Overdraft',
    '4': 'Personal loan',
    '5': 'Credit card'
  };

    export const stageValMap = {
        1: "Application Created",
        2: "Documents Pending",
        3: "Logged In",
        4: "Credit Check",
        5: "Approved",
        6: "Disbursed"
    };

    export const stagePriorityMap = {
        "application created": "1",
        "documents pending": "2",
        "logged in": "3",
        "credit check": "4",
        "approved": "5",
        "disbursed": "6"
    };

    export function getIST(){
        const formattedDate = format(new Date(), 'dd-MM-yyyy HH:mm:ss');
        return formattedDate;
      }
    
     async function postGstData(glid="", status="", registration_date="", turnover="", time="", event="",gstin="",trade_name=""){
        try{
            //console.log(glid, status, registration_date, turnover, time, event)
            const host = window.location.hostname;
            const url = host == "loans.indiamart.com" ? "https://loans.indiamart.com/api/v1/readIntent" :"https://dev-loans.indiamart.com/api/v1/readIntent";

            var data = {glid:glid, status:status, regDate:registration_date, slab:turnover, time:time, event:event, encflag:'JB7h',gstin:gstin, trade_name: trade_name};
            var formData = new FormData();
            Object.keys(data).forEach(key => {
                formData.append(key, data[key]);
            });
            
            const response = await axios.post(url, formData, {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              });
               
              //console.log("Response from postGst API => ", response)
        
        } catch (error) {
            console.error("Error in gstapifunctionAsync:", error); 
        }
    }
    
 export  async function writeGst(glid, event){
        //console.log("In Store GST Function");
        try{
        
            var data_obj = await getGstData(glid);
            var status = '', registration_date='', turnover = '',gstin,trade_name='';
    
            if (data_obj['code'] == '200') {
    
                //console.log("Data Object => ", data_obj);
                if (data_obj['data']['values']['gstin_status']) {
                    status = data_obj['data']['values']['gstin_status'];
                }
                if (data_obj['data']['values']['registration_date']) {
                    registration_date = data_obj['data']['values']['registration_date'];
                }
                if(data_obj['data']['values']['annual_turnover_slab']){
                    turnover = data_obj['data']['values']['annual_turnover_slab'];
                }
                if(data_obj['gstnum']){
                    gstin = data_obj['gstnum']
                }
                if(data_obj['data']['values']['trade_name']){
                    trade_name = data_obj['data']['values']['trade_name']
                }
            }
            var time = getIST();
            postGstData(glid, status, registration_date, turnover, time, event,gstin, trade_name);
        } catch (error) {
            console.error("Error in gstapifunctionAsync:", error); 
        }
    }

    export function checkFinaggCriteria() {
        if (localStorage.getItem("gstDetails")) {
            var gstData = JSON.parse(localStorage.getItem("gstDetails"));
        } else {
            return false;
        }
    
        if (gstData && gstData['gstdata'] && gstData['gstdata']['data'] && gstData['gstdata']['data']['values']) {
            var gstValArr = gstData['gstdata']['data']['values'];
        } else {
            return false;
        }
    
        if (!gstValArr['gstin_number'] || !gstValArr['annual_turnover_slab'] || !gstValArr['registration_date'] || !gstValArr['gstin_status']) {
            return false;
        }
    
        var gstStatus = gstValArr['gstin_status'];
        if (gstStatus != 'Active') {
            return false;
        }
    
        var turnoverSlab = gstValArr['annual_turnover_slab'];
        if (turnoverSlab.includes('NA') || turnoverSlab.includes('No') || turnoverSlab == "" || turnoverSlab.includes('0 to 40 lakhs')) {
            return false;
        }
    
        var gstRegData = gstValArr['registration_date'];
    
        if (gstRegData) {
            const [day, month, year] = gstRegData.split('-');
    
            if (day && month && year) {
                const givenDate = new Date(year, month - 1, day);
                const currentDate = new Date();
                const yearsDifference = currentDate.getFullYear() - givenDate.getFullYear();
                var gstvintage = yearsDifference * 12 + (currentDate.getMonth() - givenDate.getMonth());
    
                if (gstvintage < 24) {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
    
        return true;
    }