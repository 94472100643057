import React, { Component } from 'react'
import axios from 'axios';
import Cookies from 'universal-cookie';

import { getGlid, geturldata, gatrack, glidtracker } from '../../utils';

import downwardArrow from '../../Images/applicationsImages/downwardArrow.svg'
import ongoingImage from '../../Images/applicationsImages/ongoing.svg'
import approvedImage from '../../Images/applicationsImages/approved.svg'
import rightArrow from '../../Images/applicationsImages/RightArrow.svg'

import partitionLine from '../../Images/applicationsImages/partitionLine.svg'
import tileLine from '../../Images/applicationsImages/tileLine.svg'

import rejectedImage from '../../Images/applicationsImages/rejected.svg'
import disbursedImage from '../../Images/applicationsImages/Disbursed.svg'
import liCustomIcon from '../../Images/applicationsImages/liCustomIcon.svg'


import oneinfinity from '../../Images/lenderimages/OI_logo.jpg'
// import loantap from '../../Images/lenderimages/HQ_LoanTap_logo_.png'
import poonawalla from '../../Images/lenderimages/PFL-White-Logo.jpg'
import tataCapital from '../../Images/lenderimages/tataCapital.png'
import indialends from '../../Images/lenderimages/IL.svg'

const scrollToID = (id) => {
  const element = document.getElementById(id);
  if (element) {
    const yOffset = -80; // Adjust the offset as needed
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
};

const currentAppBoxStyle = {
  height: '56px',
  width: '81%',
  backgroundColor: 'rgba(255, 255, 255, 0.60)',
  display: 'flex',
  alignItems: 'center',


}

const currentAppTileStyle = {
  height: '56px',
  width: '81%',
}

const currentAppTextStyle = {
  fontFamily: 'Arial',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  background: 'linear-gradient(90deg, #1A56AB 0%, #28B6BF 99.95%)',
  WebkitBackgroundClip: 'text',
  backgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  textFillColor: 'transparent',
  marginLeft: '7px'
}

const barHeading = {
  fontFamily: 'Arial',
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '16px',
  letterSpacing: '0em',
  color: '#407E7B',
  marginTop: '9px'
}

const barHeading2 = {
  fontFamily: 'Arial',
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '16px',
  letterSpacing: '0em',
  color: '#02A699',
  marginTop: '9px'
}

const barFooter = {
  fontFamily: 'Arial',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '16px',
  letterSpacing: '0em',
  textAlign: 'center',
  marginTop: '6px',
  left: '-20px',
  position: 'relative'
}

const hideButton = {
  height: '46px',
  width: '97.37%',
  borderRadius: '6px',
  marginTop: '41px',
  position: 'relative',
  left: '4px',
  border: '1px solid #02A699',
  color: '#02A699',
  backgroundColor: 'white',
  fontFamily: 'Arial',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '18px',
  letterSpacing: '0em',
  textAlign: 'left',
  display: 'grid',
  textAlign: 'center',
  alignItems: 'center',
  cursor: 'pointer'
}

const oneinfstyle = {
  paddingLeft: '15px',
  paddingRight: '25px',
  width: '134px',
  height: '55px',
  marginLeft: '2px',
  marginTop: '10px'
}

const logoStyle = {
  width: '134px',
  height: '55px',
  marginLeft: '2px',
  marginTop: '10px'
}

const partitionDivStyle = {
  justifyContent: 'center',
  display: 'grid',
  marginTop: '32px'
}

const detailsHeadingStyle = {
  fontFamily: 'Arial',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0em',
  textAlign: 'left',
  color: '#757575'
}

const lostReasonStyle = {
  fontFamily: 'Arial',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0em',
  textAlign: 'left',
  color: '#4C4C4C'
}

const exploreText = {
  fontFamily: 'Arial',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '18px',
  letterSpacing: '0em',
  color: '#02A699',
  textDecoration: 'underline',
  cursor: 'pointer'

}



const poonawallaLogin = "Poonawalla Business Loan";
const poonawallaUrl = "https://poonawallafincorp.com/poonawalla-indiamart-business-loan.php?utm_source=IndiaMart_BLSF&utm_medium=website&utm_campaign=IndiaMart_BL &utm_term=business+loan&utm_content=bl&gclid=";

const creditenableLogin = "CreditEnable Unsecured";
const creditenableUrl = "https://www.creditenable.com/in/sme-business-loan/unsecured-business-loans/indiamart-sme-business-loans-v1?";

const loantapLogin = "LoanTap";
const loantapUrl = "https://loantap.in/journey/start/bl-im?";


function clicktracking(vendor_name, self) {

  var glid = getGlid();
  var host = window.location.hostname;

  if (host == 'loans.indiamart.com') {
    var getlenderorderURL = "https://loans.indiamart.com/functions.php";
  }
  else {
    var getlenderorderURL = "https://dev-loans.indiamart.com/functions.php";
  }

  var getlenderorderData = {
    "glid": glid,
    "functionName": "StoreLenderOrderData",
    "lenderorder": self.state.lenderOrder,
    "action": "Apply Now Click",
    "ApplyNow": vendor_name,
    "device": "Desktop"
  };

  var formData = new FormData();
  for (var key in getlenderorderData) {
    formData.append(key, getlenderorderData[key]);
  }

  if (glid) {
    axios({
      method: 'post',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      url: getlenderorderURL,
      data: formData,
      timeout: 10000
    }).then(function (response) {
    })
  }

}

export class CurrentApplicationsComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showCurrentAppBox: false,
      showPrevAppBox: false,
      showCurrentApp: true,
      dynamicContent: null,
      showPreviousApp: false,
      dynamicContent2: null,
      activeIndex: -1,
      url_selector: "Apply Now"
    };
  }

  toggleDetails = (index) => {
    this.setState((prevState) => ({
      activeIndex: prevState.activeIndex === index ? -1 : index,
    }));
    this.recommendPreviousApp();
  };

  logincheck = (vendor_name, vendor_url, url_selector) => {
    var glid = getGlid();

    var self = this;
    if (!glid) {
      this.setState({ show: true });
      this.setState({ vendor_name: vendor_name });
      this.setState({ vendor_url: vendor_url });
      this.setState({url_selector: url_selector});
    }
    else {
      if(vendor_name == "ICICI Business Loan" || vendor_name == "Tata Capital Business Loan" || vendor_name == "Poonawalla Business Loan" || vendor_name == "Indifi Business Loan" || vendor_name == "IIFL Business Loan")
        {
            glidtracker(vendor_name, url_selector);
            gatrack('Apply for loan',vendor_name,'Click');
            clicktracking(vendor_name,self);
        }
      else if (vendor_name == "CreditEnable Secured" || vendor_name == "CreditEnable Unsecured" || vendor_name == "Pirimid" || vendor_name == "CreditMantri" || vendor_name == "LoanTap" || vendor_name == "OneInfinity" || vendor_name =="IndiaLends" || vendor_name == "Velocity"  || vendor_name == "FinAgg") {
        geturldata(vendor_name, url_selector);
        var product_name = vendor_name + " Business Loan"
        gatrack('Apply for loan', product_name, 'Click');
        clicktracking(product_name, self);
      }
      else {
        gatrack('Apply for loan', vendor_name, 'Click');
        clicktracking(vendor_name, self);
        window.open(vendor_url, '_blank');
      }
    }

  }

  userCurrentStatus(glid) {
    return new Promise((resolve) => {
        var flag = false;

        var host = window.location.hostname;

        var statusUrl = host == 'loans.indiamart.com' ? 'https://loans.indiamart.com/api/v1/checkCurrentStatus' : 'https://dev-loans.indiamart.com/api/v1/checkCurrentStatus';

        var statusData = {
            "glid": glid,
        };

        var formData = new FormData();
        for (var key in statusData) {
            formData.append(key, statusData[key]);
        }

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: statusUrl,
            data: JSON.stringify(statusData),
            timeout: 10000
        }).then((response) => {
            if (response['data'] == true) {
                flag = true;
            }
            resolve(flag);
        }).catch(error => {
            console.error("Error occurred: ", error);
            // Resolve with false if an error occurs
            resolve(false);
        });
    });
}

userPrevStatus(glid) {
  return new Promise((resolve) => {
      var flag = false;

      var host = window.location.hostname;

      var statusUrl = host == 'loans.indiamart.com' ? 'https://loans.indiamart.com/api/v1/checkPrevStatus' : 'https://dev-loans.indiamart.com/api/v1/checkPrevStatus';

      var statusData = {
          "glid": glid,
      };

      var formData = new FormData();
      for (var key in statusData) {
          formData.append(key, statusData[key]);
      }

      axios({
          method: 'post',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          url: statusUrl,
          data: JSON.stringify(statusData),
          timeout: 10000
      }).then((response) => {
          if (response['data'] == true) {
              flag = true;
          }
          resolve(flag);
      }).catch(error => {
          console.error("Error occurred: ", error);
          // Resolve with false if an error occurs
          resolve(false);
      });
  });
}

  recommendCurrentApp = () => {
    var glid = getGlid();

    var host = window.location.hostname;

    if (host == 'loans.indiamart.com') {
      var currentAppURL = 'https://loans.indiamart.com/functions.php';
    }
    else {
      var currentAppURL = 'https://dev-loans.indiamart.com/functions.php';
    }

    var currentAppData = {
      "functionName": "recommendCurrentApp",
      "glid": glid,
    };

    var formData = new FormData();
    for (var key in currentAppData) {
      formData.append(key, currentAppData[key]);
    }

    axios({
      method: 'post',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      url: currentAppURL,
      data: currentAppData,
      timeout: 10000
    }).then((response) => {

      if (response) {
        //console.log(response);

        var data_obj = response['data'];
        var currentAppElements = Object.values(data_obj);

        const barHeadingMap = {
          'approved': <div style={barHeading2}>Just 1 click away!</div>,
          'credit check': (<div style={barHeading}>One step away!</div>),
          'logged in': (<div style={barHeading}>One step away!</div>),
          'documents pending': (<div style={barHeading}>Two steps away!</div>)
        }

        const lenderImageMap = {
          'indifi': "https://loans.imimg.com/images/loans/lenderimages/Indifi_logo-01.png",
          'icici': "https://loans.imimg.com/images/loans/lenderimages/ICICI_Bank_Logo-01.jpg",
          'iifl': "https://loans.imimg.com/images/loans/lenderimages/IIFL-New-Common-logo.png",
          'lkart': "https://loans.imimg.com/images/loans/lenderimages/lendingkartlogo-.png",
          'creditenable': "https://loans.imimg.com/images/loans/lenderimages/CreditEnable_Logo_Dark_Blue.png",
          'creditmantri': "https://loans.imimg.com/images/loans/lenderimages/CM-Logo-1920.png",
          'andromeda': "https://loans.imimg.com/images/loans/lenderimages/Andromeda.jpeg(2)(1).jpg",
          'psb': "https://loans.imimg.com/images/loans/lenderimages/PSB59-Logo-for-IndiaMart.png",
          'oneinfinity': oneinfinity,
          'loantap': "https://loans.imimg.com/images/loans/lenderimages/HQ_LoanTap_logo_.png",
          'poonawalla': poonawalla,
          'tatacapital': tataCapital,
          'indialends':indialends,
          'velocity':'https://loans.imimg.com/images/loans/lenderimages/velocity_logo.png',
          'finagg':'https://loans.imimg.com/images/loans/lenderimages/finagg_logo_new.png'
        }

        const stageImageMap = {
          'approved': (<img src={approvedImage} className='approvedBlink' style={{marginTop:'5px', marginLeft:'10px' }}/>),
          'credit check': (<img src={ongoingImage} style={{marginTop:'5px', marginLeft:'10px' }} />),
          'logged in': (<img src={ongoingImage} style={{marginTop:'5px', marginLeft:'10px' }}/>),
          'documents pending': (<img src={ongoingImage} style={{marginTop:'5px', marginLeft:'10px' }}/>),
        }

        const progressBarMap = {
          'approved': (<div className="progress-bar">
            <div className="progress-stage stage4">
              <div className="progress-dot" style={{ left: '27.8%', backgroundColor: '#FFFFFF' }}></div>
              <div className="progress-dot" style={{ left: '55.5%', backgroundColor: '#FFFFFF' }}></div>
              <div className="progress-dot" style={{ left: '83.3%', backgroundColor: '#FFFFFF' }}></div>
            </div>
          </div>),
          'credit check': (<div className="progress-bar">
            <div className="progress-stage stage3">
              <div className="progress-dot" style={{ left: '31.25%', backgroundColor: '#FFFFFF' }}></div>
              <div className="progress-dot" style={{ left: '62.5%', backgroundColor: '#FFFFFF' }}></div>
              <div className="progress-dot" style={{ left: '93.75%', backgroundColor: '#FFFFFF' }}></div>
            </div>
          </div>),
          'logged in': (<div className="progress-bar">
            <div className="progress-stage stage2">
              <div className="progress-dot" style={{ left: '41.66%', backgroundColor: '#FFFFFF' }}></div>
              <div className="progress-dot" style={{ left: '83.33%', backgroundColor: '#FFFFFF' }}></div>
            </div>
            <div className="progress-dot" style={{ left: '75%' }}></div>
          </div>),
          'documents pending': (<div className="progress-bar">
            <div className="progress-stage stage1">
              <div className="progress-dot" style={{ left: '83.33%', backgroundColor: '#FFFFFF' }}></div>
            </div>
            <div className="progress-dot"></div>
            <div className="progress-dot"></div>
          </div>)
        }

        const barFooterMap = {
          'approved': (<div style={{ ...barFooter}}>Your Application has been <b>Approved</b></div>),
          'credit check': (<div style={barFooter}>Your Application is <b>Under Review</b></div>),
          'logged in': (<div style={barFooter}>Your Application is <b>Under Review</b></div>),
          'documents pending': (<div style={{ ...barFooter}}>You are yet to <b>Submit Your Documents</b></div>)
        }

        const ctaMap = {
          'approved': 'Get Disbursal',
          'credit check': 'Complete Your application',
          'logged in': 'Complete Your application',
          'documents pending': 'Complete Your application'
        }

        var currentAppTiles = '';

        currentAppTiles = (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {
              currentAppElements.map((data, index) => {
                
                return (
                  <div key={index} style={{ width: '81%', height: '124px', backgroundColor: 'white', marginTop: '16px' }}>
                    <div className='container' style={{paddingTop:'0px', paddingLeft:'0px'}}>
                      <div className='row'>
                        <div className='col-2'>
                          <span>{stageImageMap[data.stage]}</span>
                          <span style={{marginLeft:'10px'}}><img src={lenderImageMap[data.lenderName]} style={data.lenderName === 'oneinfinity' ? oneinfstyle : logoStyle} alt="Logo" className="img-fluid img-thumbnail img-responsive" /></span>
                        </div>

                        <div className='col-7'>
                          <div className='container'>
                            <div className='row'>
                              <div style={{ marginLeft: '16px' , marginTop:'10px'}}>
                                {barHeadingMap[data.stage]}
                                <div>
                                  {progressBarMap[data.stage]}
                                </div>
                                {barFooterMap[data.stage]}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='col-3'>
                          <div className = 'getDisbursal' onClick={data.lenderName === 'loantap' ? () => this.logincheck(loantapLogin, loantapUrl) : data.lenderName === 'poonawalla' ? () => this.logincheck(poonawallaLogin, poonawallaUrl) : () => this.logincheck([data.logincheck], [data.applications_url])}>{ctaMap[data.stage]}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        );

        this.setState({ dynamicContent: currentAppTiles });

        //console.log(this.state.dynamicContent);
      }
      // else {
      //   console.log('no response');
      // }
    })
  }

  recommendPreviousApp = () => {
    var glid = getGlid();

    var host = window.location.hostname;

    if (host == 'loans.indiamart.com') {
      var previousAppURL = 'https://loans.indiamart.com/api/v1/getPreviousApp';
    }
    else {
      var previousAppURL = 'https://dev-loans.indiamart.com/api/v1/getPreviousApp';
    }

    var previousAppData = {
      "glid": glid,
    };

    var formData = new FormData();
    for (var key in previousAppData) {
      formData.append(key, previousAppData[key]);
    }

    axios({
      method: 'post',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      url: previousAppURL,
      data: formData,
      timeout: 10000
    }).then((response) => {

      if (response && response['data'] != null) {
        //console.log(response);

        var data_obj = response['data'];
        var previousAppElements = Object.values(data_obj);

        const barHeadingMap = {
          'rejected': (<div style={{ ...barHeading2, color: '#FA6464' }}>Rejected</div>),
          'successful': (<div style={{ ...barHeading, color: '#02A699' }}>Successful</div>),
        }

        const lenderImageMap = {
          'indifi': "https://loans.imimg.com/images/loans/lenderimages/Indifi_logo-01.png",
          'icici': "https://loans.imimg.com/images/loans/lenderimages/ICICI_Bank_Logo-01.jpg",
          'iifl': "https://loans.imimg.com/images/loans/lenderimages/IIFL-New-Common-logo.png",
          'lkart': "https://loans.imimg.com/images/loans/lenderimages/lendingkartlogo-.png",
          'creditenable': "https://loans.imimg.com/images/loans/lenderimages/CreditEnable_Logo_Dark_Blue.png",
          'creditmantri': "https://loans.imimg.com/images/loans/lenderimages/CM-Logo-1920.png",
          'andromeda': "https://loans.imimg.com/images/loans/lenderimages/Andromeda.jpeg(2)(1).jpg",
          'psb': "https://loans.imimg.com/images/loans/lenderimages/PSB59-Logo-for-IndiaMart.png",
          'oneinfinity': oneinfinity,
          'loantap': "https://loans.imimg.com/images/loans/lenderimages/HQ_LoanTap_logo_.png",
          'poonawalla': poonawalla,
          'tatacapital': tataCapital,
          'indialends':indialends,
          'velocity':'https://loans.imimg.com/images/loans/lenderimages/velocity_logo.png',
          'finagg':'https://loans.imimg.com/images/loans/lenderimages/finagg_logo_new.png'
        }

        const statusImageMap = {
          'rejected': rejectedImage,
          'successful': disbursedImage,
        }

        const progressBarMap = {
          'disbursed': (<div className="progress-bar">
            <div className="progress-stage stage5">
              <div className="progress-dot" style={{ backgroundColor: '#FFFFFF' }}></div>
              <div className="progress-dot" style={{ backgroundColor: '#FFFFFF' }}></div>
              <div className="progress-dot" style={{ backgroundColor: '#FFFFFF' }}></div>
            </div>
          </div>),
          'approved': (<div className="progress-bar">
            <div className="progress-stage stage4" style={{ backgroundColor: '#FA6464' }}>
              <div className="progress-dot" style={{ left: '27.8%', backgroundColor: '#FFFFFF' }}></div>
              <div className="progress-dot" style={{ left: '55.5%', backgroundColor: '#FFFFFF' }}></div>
              <div className="progress-dot" style={{ left: '83.3%', backgroundColor: '#FFFFFF' }}></div>
            </div>
          </div>),
          'credit check': (<div className="progress-bar">
            <div className="progress-stage stage3" style={{ backgroundColor: '#FA6464' }}>
              <div className="progress-dot" style={{ left: '31.25%', backgroundColor: '#FFFFFF' }}></div>
              <div className="progress-dot" style={{ left: '62.5%', backgroundColor: '#FFFFFF' }}></div>
              <div className="progress-dot" style={{ left: '93.75%', backgroundColor: '#FFFFFF' }}></div>
            </div>
          </div>),
          'logged in': (<div className="progress-bar">
            <div className="progress-stage stage2" style={{ backgroundColor: '#FA6464' }}>
              <div className="progress-dot" style={{ left: '41.66%', backgroundColor: '#FFFFFF' }}></div>
              <div className="progress-dot" style={{ left: '83.33%', backgroundColor: '#FFFFFF' }}></div>
            </div>
            <div className="progress-dot" style={{ left: '75%' }}></div>
          </div>),
          'documents pending': (<div className="progress-bar">
            <div className="progress-stage stage1" style={{ backgroundColor: '#FA6464' }}>
              <div className="progress-dot" style={{ left: '83.33%', backgroundColor: '#FFFFFF' }}></div>
            </div>
            <div className="progress-dot"></div>
            <div className="progress-dot"></div>
          </div>)
        }

        const barFooterMap = {
          'approved': (<div style={{ ...barFooter, left: '33%' }}>Approved</div>),
          'credit check': (<div style={barFooter}>Credit Check</div>),
          'logged in': (<div style={barFooter}>Logged In</div>),
          'documents pending': (<div style={{ ...barFooter, left: '-24%' }}>Documents Pending</div>),
          'disbursed': (<div style={{ ...barFooter }}>Your Application was <b>Disbursed Successfully</b></div>)
        }

        // const ctaMap = {
        //   'rejected':(<div className = 'getDisbursal' >View Details</div>),
        //   'successful':(<div style={{height: '46px', width: '97.37%'}}></div>)

        // }

        const lostReasonMap = {
          'aaa': (<ul>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your Age of Business might be less than the lender's criterion.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your Annual Sales might be less than the lender's criterion.
            </li>
          </ul>),
          'aab': (<ul>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>You would have applied to the same lender in the last 60/90 days.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>You would have applied for a loan at a lot of other lenders in the last 6 months.
            </li>
          </ul>),
          'aac': (<ul>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your industry might not be serviceable by the lender.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your area of business or home might not be serviceable for the lender.
            </li>
          </ul>),
          'aad': (<ul>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your age might be less than lender’s criterion
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>You would have applied to the same lender in the last 60/90 days.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your area of business or home might not be serviceable for the lender.
            </li>
          </ul>),
          'aae': (<ul>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>You would have applied to the same lender in last 60/90 days.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>You would have applied for a loan at a lot of other lenders in the last 6 months.
            </li>
          </ul>),
          'aaf': (<ul>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>The lender policy might only be specific for proprietorship firms,You can choose other lenders - and go ahead with your application.
            </li>
          </ul>),
          'aag': (<ul>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your GST parameters are not suitable for the lender’s policy
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your Annual Sales might be less than the lender's criterion.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your Age of Business might be less than the lender's criterion.
            </li>
          </ul>),
          'aah': (<ul>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your GST parameters are not suitable for the lender’s policy
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your Annual Sales might be less than the lender's criterion.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your Age of Business might be less than the lender's criterion.
            </li>
          </ul>),
          'aai': (<ul>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your CIBIL score might be lower than the lender’s criterion.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>You might have recent DPDs / overdues / write-offs in the bureau.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>You might have applied to a lot of lenders for loans recently.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your income and obligations might not be sufficient to get another loan.
            </li>
          </ul>),
          'aaj': (<ul>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your CIBIL score might be lower than the lender’s criterion.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>You might have recent DPDs / overdues / write-offs in the bureau.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>You might have applied to a lot of lenders for loans recently.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your income and obligations might not be sufficient to get another loan.
            </li>
          </ul>),
          'aak': (<ul>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your CIBIL score might be lower than the lender’s criterion.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>You might have recent DPDs / overdues / write-offs in the bureau.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>You might have applied to a lot of lenders for loans recently.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your income and obligations might not be sufficient to get another loan.
            </li>
          </ul>),
          'aal': (<ul>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your CIBIL score might be lower than the lender’s criterion.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>You might have recent DPDs / overdues / write-offs in the bureau.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>You might have applied to a lot of lenders for loans recently.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your income and obligations might not be sufficient to get another loan.
            </li>
          </ul>),
          'aam': (<ul>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>You would have applied to the same lender in last 60/90 days.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>You would have applied for a loan at a lot of other lenders in the last 6 months.
            </li>
          </ul>),
          'aan': (<ul>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your average banking balance would be lower than lender’s policy.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your transactions might not be sufficient as per lender’s policy.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your Annual sales might be lower than lender’s criterion.
            </li>
          </ul>),
          'aao': (<ul>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your application might not be fitting as per lender policy.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your age of business might be lower than lender’s criterion.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your annual sales might be lower than lender’s criterion.
            </li>
          </ul>),
          'aap': (<ul>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your average banking balance would be lower than lender’s policy.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your transactions might not be sufficient as per lender’s policy.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your Annual sales might be lower than lender’s criterion.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>You might have some recent cheque bounces.
            </li>
          </ul>),
          'aaq': (<ul>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your average banking balance would be lower than lender’s policy.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your transactions might not be sufficient as per lender’s policy.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your Annual sales might be lower than lender’s criterion
            </li>
          </ul>),
          'aar': (<ul>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your average banking balance would be lower than lender’s policy.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your transactions might not be sufficient as per lender’s policy.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your Annual sales might be lower than lender’s criterion
            </li>
          </ul>),
          'aas': (<ul>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your average banking balance would be lower than lender’s policy.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your transactions might not be sufficient as per lender’s policy.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your Annual sales might be lower than lender’s criterion.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>You might have some recent cheque bounces.
            </li>
          </ul>),
          'aat': (<ul>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your average banking balance would be lower than lender’s policy.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your transactions might not be sufficient as per lender’s policy.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your Annual sales might be lower than lender’s criterion.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>You might have some recent cheque bounces.
            </li>
          </ul>),
          'aau': (<ul>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your average banking balance would be lower than lender’s policy.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your transactions might not be sufficient as per lender’s policy.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your Annual sales might be lower than lender’s criterion.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>You might have some recent cheque bounces.
            </li>
          </ul>),
          'aav': (<ul>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your average banking balance would be lower than lender’s policy.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your transactions might not be sufficient as per lender’s policy.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your Annual sales might be lower than lender’s criterion.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>You might have some recent cheque bounces.
            </li>
          </ul>),
          'aaw': (<ul>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your application might not be fitting as per lender policy.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your age of business might be lower than lender’s criterion.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your annual sales might be lower than lender’s criterion.
            </li>
          </ul>),
          'aax': (<ul>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your application might have remained inactive for more than 45 days.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>You might have chosen to withdraw your application with the lender.
            </li>
          </ul>),
          'aay': (<ul>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your application might have remained inactive for more than 45 days.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>You might have chosen to withdraw your application with the lender.
            </li>
          </ul>),
          'aaz': (<ul>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your application might have remained inactive for more than 45 days.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>You might have chosen to withdraw your application with the lender.
            </li>
          </ul>),
          'aba': (<ul>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your age might be less than lender’s criterion
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>You would have applied to the same lender in the last 60/90 days.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your area of business or home might not be serviceable for the lender.
            </li>
          </ul>),
          null: (<ul>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your CIBIL score might be lower than the lender’s criterion.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>You might have recent DPDs / overdues / write-offs in the bureau.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>You might have applied to a lot of lenders for loans recently.
            </li>
            <li style={{ ...lostReasonStyle, listStyleType: 'none' }}>
              <span style={{ marginRight: '8px' }}><img src={liCustomIcon} /></span>Your income and obligations might not be sufficient to get another loan.
            </li>
          </ul>)

        }

        var previousAppTiles = '';

        previousAppTiles = (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {
              previousAppElements.map((data, index) => {
                // console.log(data.lenderName);
                // console.log(data.stage);
                return (
                  <div key={index} style={{ width: '81%', backgroundColor: 'white', marginTop: '16px' }}>
                    <div className='container' style={{paddingLeft:'0px', paddingTop:'0px'}}>
                      <div className='row'>
                        <div className='col-2'>
                          <span><img src={statusImageMap[data.status]} style={{marginTop:'5px', marginLeft:'10px' }}/></span>
                          <span style={{marginLeft:'10px'}}><img src={lenderImageMap[data.lenderName]} style={data.lenderName === 'oneinfinity' ? oneinfstyle : logoStyle} alt="Logo" className="img-fluid img-thumbnail img-responsive" /></span>
                        </div>

                        <div className='col-7'>
                          <div className='container'>
                            <div className='row'>
                              <div style={{ marginLeft: '16px', marginBottom: '14px', marginTop:'10px' }}>
                                {barHeadingMap[data.status]}
                                <div>
                                  {progressBarMap[data.stage]}
                                </div>
                                {barFooterMap[data.stage]}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='col-3'>
                          {data.status === 'rejected' ? (
                            this.state.activeIndex === index ? (<div>
                              <div onClick={() => this.toggleDetails(index)}>
                                <div style={hideButton}>Hide Details</div>
                              </div>
                              <div style={{ display: 'grid', justifyContent: 'center', marginTop: '8px' }}>
                                <div style={exploreText} onClick={() => scrollToID('exploreOtherOffers')}>Explore Other Offers</div>
                              </div>
                            </div>
                            ) : (<div>
                              <div className = 'getDisbursal' onClick={() => this.toggleDetails(index)}>View Details</div>
                              <div style={{ display: 'grid', justifyContent: 'center', marginTop: '8px' }}>
                                <div style={exploreText} onClick={() => scrollToID('exploreOtherOffers')}>Explore Other Offers</div>
                              </div>
                            </div>
                            )
                          ) : (
                            <div className = 'getDisbursal' onClick={() => scrollToID('exploreOtherOffers')}>Get Another Loan</div>
                          )}
                        </div>
                      </div>
                    </div>
                    {this.state.activeIndex === index && (
                      <div>
                        <div style={{ justifyContent: 'center', display: 'inline-flex' }}><img src={tileLine} style={{ width: '97%' }} /></div>
                        <div style={{ margin: '12px' }}>
                          <div style={detailsHeadingStyle}>Your application might have been rejected because of one / more of the below reasons:</div>
                          <div>{lostReasonMap[data.lostReason]}</div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })
            }
          </div>
        );

        this.setState({ dynamicContent2: previousAppTiles });

        //console.log(this.state.dynamicContent);
      }
      // else {
      //   console.log('no response');
      // }
    })
  }

  handleClick1 = () => {
    this.setState({ showCurrentApp: !this.state.showCurrentApp });
  };

  handleClick2 = () => {
    this.setState({ showPreviousApp: !this.state.showPreviousApp });
  };


  async componentDidMount() {
    const cookie = new Cookies();

    var visitor_name = '', glid = '';

    let visitorData = cookie.get('ImeshVisitor');
    if (visitorData) {
      visitorData = visitorData.split("|");

      let sd = [];
      visitorData.map((data) => {
        sd = data.split("=")
        if (sd[0] == "fn") {
          visitor_name = sd[1]
        }
        if (sd[0] == "glid") {
          glid = sd[1]
        }
      })
    }

    try {
      const flag1 = await this.userCurrentStatus(glid);
      this.setState({ showCurrentAppBox: flag1});
    } catch (error) {
      console.error("Error fetching user status:", error);
    }

    try {
      const flag2 = await this.userPrevStatus(glid);
      this.setState({ showPrevAppBox: flag2 });
    } catch (error) {
      console.error("Error fetching user status: ", error);
    }

    this.recommendCurrentApp();
    this.recommendPreviousApp();

  }


  render() {
    return (
      <>
        <section style={{ backgroundColor: '#E8F2FF', marginTop:'45px'}}>
        {this.state.showCurrentAppBox && <div>
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '32px', cursor: 'pointer' }}>
            <div ref={this.props.forwardedRef} style={currentAppBoxStyle} onClick={this.handleClick1}>
              <span style={{ marginLeft: '12px' }}>
                {this.state.showCurrentApp ? (<img src={downwardArrow} />) : (<img src={rightArrow} />)}
              </span>
              <span style={currentAppTextStyle}>Current Applications</span>
            </div>
          </div>

          {this.state.showCurrentApp && this.state.showCurrentAppBox &&
            <div >
              {this.state.dynamicContent}
            </div>
          }</div>}

          {this.state.showCurrentAppBox && this.state.showPrevAppBox &&<div style={partitionDivStyle}><img src={partitionLine} /></div>}
          

          {this.state.showPrevAppBox && <div>
            <div style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer', paddingTop: '32px'}}>
            <div style={currentAppBoxStyle} onClick={this.handleClick2}>
              <span style={{ marginLeft: '12px' }}>
                {this.state.showPreviousApp ? (<img src={downwardArrow} />) : (<img src={rightArrow} />)}
              </span>
              <span style={currentAppTextStyle}>Previous Applications</span>
            </div>
          </div>

          {this.state.showPreviousApp && this.state.showPrevAppBox &&
            <div >
              {this.state.dynamicContent2}
            </div>
          }</div>}

        </section>
      </>
    )
  }
}

export default React.forwardRef((props, ref) => (
  <CurrentApplicationsComponent forwardedRef={ref} {...props} />
));